@import 'variables';

@font-face {
  font-family: 'FiraSans-Black';
  src: local('FiraSans-Black'),
    url(./../assets/fonts/FiraSans-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Bold';
  src: local('FiraSans-Bold'),
    url(./../assets/fonts/FiraSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-ExtraBold';
  src: local('FiraSans-ExtraBold'),
    url(./../assets/fonts/FiraSans-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-ExtraLight';
  src: local('FiraSans-ExtraLight'),
    url(./../assets/fonts/FiraSans-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Light';
  src: local('FiraSans-Light'),
    url(./../assets/fonts/FiraSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Medium';
  src: local('FiraSans-Medium'),
    url(./../assets/fonts/FiraSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Regular';
  src: local('FiraSans-Regular'),
    url(./../assets/fonts/FiraSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-SemiBold';
  src: local('FiraSans-SemiBold'),
    url(./../assets/fonts/FiraSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Thin';
  src: local('FiraSans-Thin'),
    url(./../assets/fonts/FiraSans-Thin.ttf) format('truetype');
}
iframe {
  pointer-events: none;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666 !important;
}
:disabled {
  pointer-events: unset !important;
  cursor: not-allowed !important;
}
.disable {
  cursor: not-allowed !important;
  color: #666666 !important;
}
.headerAddButtonDisable {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666 !important;
}
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  font-family: 'FiraSans-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5 !important;
}
button {
  box-shadow: unset !important;
}
.cancel-btn {
  height: 48px !important ;
  width: 170px !important;
  border-radius: 4px !important;
  background-color: #d1d1d1 !important;
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-size: 0.9375rem !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}
.add-btn {
  height: 48px !important;
  width: 170px !important;
  border-radius: 4px !important;
  background-color: #dec330;
  color: #ffffff;
  text-transform: uppercase !important;
  font-size: 0.9375rem !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.error-msg {
  color: #da5a30;
  font-family: 'FiraSans-Regular' !important;
  font-size: 0.6875rem !important;
  font-weight: 400 !important;
  width: 100%;
}
#root {
  height: inherit;
}

.MuiPickersDay-root {
  background-color: #fff !important;
  &.Mui-selected {
    color: #ffffff !important;
    background-color: #0062ff !important;
  }
}
code {
  font-family: 'FiraSans-Medium';
}

div,
h1,
h2,
h3,
a,
p,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
.app-loader {
  width: 50%;
  height: 50%;
}
.flex-centering {
  display: flex;
  flex-direction: column;
  height: inherit;
  align-items: center;
  justify-content: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.margin-h-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.center-content {
  justify-content: center;
}

.space-between-content {
  justify-content: space-between;
}

.no-padding {
  padding: 0 !important;
}
.no-padding-horizontal {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.test {
  background-color: red;
}

/*
  * Mui form global css starts here
   */

.MuiFormHelperText-root {
  // position: absolute;
  background-color: #fff !important;
  bottom: 0;
}

button.MuiLink-root {
  &:focus {
    outline: 0;
  }
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #b1b4be;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b1b4be;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b1b4be;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1b4be;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b1b4be;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #b1b4be;
}

.file-drop {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}

.file-drop-target {
  width: 100%;
}

// body {
//   background-color: transparent !important;
//   background: transparent !important;
// }
.window {
  transform: scale(0.96);
  box-shadow: 0px 2px 10px 0px #a0a0a04f;
  border-radius: 4px;
  overflow: hidden;
}
.flex-row-justify-center {
  justify-content: center;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-center-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.align-flex-end {
  align-items: flex-end;
}
.window-margin {
  margin-right: 10px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.column-v-center {
  justify-content: center;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.space-between {
  justify-content: space-between;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.capitalize {
  text-transform: capitalize;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.content-body {
  height: 100%;
  padding-top: 5rem;
}
.MuiDivider-fullWidth {
  margin-left: 4px !important;
}
.MuiInput-input,
.MuiOutlinedInput-root {
  height: 43px !important;
  border-radius: 7px !important;
  background-color: #f8f8fa !important;
  font-size: 0.875rem !important;
  color: #241a2e !important ;
  font-family: 'FiraSans-Medium' !important;
}
input {
  &:focus-visible {
    outline: unset !important;
  }
}
select {
  padding-left: 8px !important;
  text-transform: capitalize;
}
// .MuiInput-input {
.MuiInputBase-input {
  &.Mui-disabled {
    font-size: 0.875rem !important;
    color: #979598 !important;
    font-family: 'FiraSans-Medium' !important;
    text-decoration-color: #979598 !important;
    -webkit-text-fill-color: unset !important;
  }
}
// }
.MuiInput-root:before,
.MuiInput-root:after {
  border-bottom: unset !important;
}
.MuiInput-input {
  padding-left: 0.5rem !important;
  padding-top: unset !important;
  padding-bottom: unset !important;
  padding-right: 0.5rem !important;
}
.MuiOutlinedInput-notchedOutline {
  border: unset !important;
}
// .MuiGrid-root {
// min-height: auto !important;
// }
.custom-error {
  color: #da5a30 !important;
  font-size: 0.6875rem;
}
.MuiGrid-root {
  .MuiCheckbox-colorSuccess {
    padding-left: 0px !important;
  }
}
.MuiAccordionDetails-root {
  padding: 0 0 10px 10px !important;
}
.MuiPaper-root {
  & .MuiAccordionSummary-root {
    min-height: 48px !important;
  }
  &.MuiAccordion-root {
    & .Mui-expanded {
      margin: unset !important;
      // .menu-text {
      // color: #ffffff !important;
      // }
      .company-menu-text {
        color: #241a2e !important;
        font-size: 0.9375rem !important;
        font-family: FiraSans-Medium !important;
        padding-left: 0.5rem;
      }
    }
  }
}
.company-menu-text {
  color: #241a2e !important;
  font-size: 0.9375rem !important;
  font-family: FiraSans-Medium !important;
  padding-left: 0.5rem;
}
.MuiButtonBase-root {
  // min-height: 48px !important;
  & .MuiAccordionSummary-root {
    &.Mui-expanded {
      min-height: auto !important;
    }
  }
}
.MuiAccordionSummary-expandIconWrapper {
  margin-right: 12px;
}
.MuiSvgIcon-root {
  fill: #979598;
}
.MuiPaper-root {
  &.MuiAccordion-root {
    &::before {
      background-color: unset !important;
    }
    border-radius: 8px 0 0 8px;
    min-height: 48px;
    background-color: unset !important;
    box-shadow: unset;
    .MuiAccordionSummary-expandIconWrapper {
      color: #979598;
    }
    &:hover {
      color: unset !important;
      // .MuiAccordionSummary-expandIconWrapper,
      // .menu-text {
      // color: #ffffff !important;
      // }
    }
  }
  .MuiButtonBase-root {
    &.MuiAccordionSummary-root {
      padding: unset !important;
    }
  }
  .MuiAccordionSummary-content {
    margin: unset !important;
    padding: 0.6rem 0;
  }
  .menu-text {
    color: #979598;
    font-size: 0.9375rem;
    font-weight: 500;
    padding-left: 0.5rem;
  }

  .inner-menu {
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    //opacity: 0.25;
    .act-btn {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin: 0 12px 0 8px;
    }
    .portalColor {
      background-color: #dec330;
    }
    .companyPortalColor {
      background-color: #241a2e;
    }
    .hidden {
      visibility: hidden;
    }
  }
  .menu-item {
    align-items: center;
    display: flex;
    margin-bottom: 0.3rem;
    padding: 0em 1rem;
    &:hover {
      background: unset !important;
    }
  }
}
.filter {
  p,
  h3 {
    color: #ffffff;
  }
  p {
    font-size: 0.9375rem !important;
  }
  input[type='checkbox'] {
    height: 18px;
    width: 18px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #403849 !important;
    &:focus-visible {
      outline: unset !important;
      border: unset;
    }
  }
  input[type='text'] {
    background-color: #403849;
    height: 0px !important;
  }
  .MuiInputBase-colorPrimary {
    background-color: unset !important;
  }
  h3 {
    font-size: 0.9375rem;
    font-family: 'FiraSans-Medium';
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
  }
  .MuiPaper-root {
    &.MuiMenu-paper {
      &.MuiPaper-root {
        &.MuiPopover-paper {
          left: unset !important;
          right: 0;
        }
      }
    }
  }
  .MuiList-root {
    &.MuiMenu-list {
      // min-height: 400px;
      width: 320px !important;
      border-radius: 8px;
      background-color: #241a2e;
      box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.22);
    }
  }
  .MuiTypography-root {
    color: $default-white-color !important;
  }
  .add-btn {
    width: 100% !important;
  }
}
.main {
  display: block;
  position: relative;
  cursor: pointer;
  margin-top: -0.5rem;
  /* Hide the default checkbox */
  input[type='checkbox'] {
    visibility: hidden;
  }
}
/* Creating a custom checkbox
  +based on demand */
.geekmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #ffffff;
}
/* Specify the background color to be
  +shown when checkbox is checked */
.main input:checked ~ .geekmark {
  background-color: #0da728;
}
/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.main input:checked ~ .geekmark:after {
  display: block;
}
/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
  +showing only two border to make it look
  +like a tickmark */
.main .geekmark:after {
  left: 9px;
  bottom: 5px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.active-toggle {
  color: unset !important;
  border-radius: 8px 0 0 8px !important;
  background: linear-gradient(
    90deg,
    #52442e 0%,
    #52442e 68.69%,
    #40332e 100%
  ) !important;
  min-height: 48px;
  .MuiAccordionSummary-expandIconWrapper {
    color: #ffffff !important;
  }
  .menu-text {
    color: #ffffff !important;
  }
  .a-inner-menu {
    border-radius: 8px 0 0 8px;
    background-color: #635540 !important;
  }
}
.company-active-toggle {
  font-weight: 600 !important;
  color: #241a2e !important;
  border-radius: 8px 0 0 8px !important;
  background: linear-gradient(
    90deg,
    #dec330 0%,
    #dec330 68.69%,
    rgba(222, 195, 48, 0.3) 100%
  ) !important;
  min-height: 48px;
  box-shadow: -1px 3px 2px rgb(0 0 0 / 11%) !important;
  .MuiAccordionSummary-expandIconWrapper {
    color: #241a2e !important;
  }
  .menu-text {
    color: #241a2e !important;
    font-family: FiraSans-Medium !important;
  }

  .a-inner-menu {
    border-radius: 8px 0 0 8px;
    background-color: rgba(197, 172, 35, 0.3) !important;
  }
}
table {
  th {
    padding: 0px 16px !important;
    border-bottom: unset !important;
  }
  td {
    border-bottom: 1px solid #e8e8e8 !important;
  }
  tbody {
    background-color: #ffffff !important;
  }
}
.popclass {
  .MuiPaper-elevation {
    //padding: unset !important;
    left: unset !important;
    right: 2.5vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -7px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    transition: 0.5s;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
  .custom-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    white-space: nowrap;
    &:hover {
      background-color: #37414f;
    }
  }
}
.Driver-popclass {
  .MuiPaper-elevation {
    //padding: unset !important;
    left: unset !important;
    right: 4.1vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -7px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
}
.training-popover-class {
  .MuiPaper-elevation {
    left: unset !important;
    right: 4.1vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -7px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
}
.admin-popclass {
  .MuiPaper-elevation {
    //padding: unset !important;
    left: unset !important;
    right: 6.1vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -7px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
}
.role-popclass {
  .MuiPaper-elevation {
    //padding: unset !important;
    left: unset !important;
    right: 2.1vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -7px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
}
.Document-popclass {
  .MuiPaper-elevation {
    //padding: unset !important;
    left: unset !important;
    right: 5.5vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -7px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
}
#driver-search-table .Mui-error {
  color: #a6a2ab !important;
  font-size: 14px;
  font-weight: 500;
}
#driver-search-table .MuiFormControl-root.MuiTextField-root {
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px;
}
.attachment {
  .menu-text {
    color: #979598;
    font-size: 0.9375rem;
    font-weight: 500;
  }
  .link-text {
    color: #0061ff;
    cursor: pointer;
    font-size: 16px;
    text-align: right;
    text-decoration: underline solid #0061ff;
    margin-left: 1rem;
  }
}
.error-msg {
  color: #da5a30;
  font-size: 0.6875rem;
  letter-spacing: 0.33px;
  font-family: FiraSans-Regular;
  font-weight: 400;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.profile-upload {
  margin-top: 3px;
  margin-left: 4px;
  font-weight: 500;
  text-decoration: underline;
}
.modal-header {
  display: flex;
  justify-content: center;
  p {
    font-size: 20px;
    font-weight: 500;
    color: #241a2e;
    font-family: 'FiraSans-Medium';
    line-height: 30px;
  }
}
.modal-body {
  font-size: 16px;
  font-family: 'FiraSans-Medium';
  line-height: 20px;
  min-height: 80px;
  .body-header {
    color: #241a2e;
  }
  .body-text {
    color: #979598;
  }
}
.modal-footer {
  display: flex;
  justify-content: space-between;
  .cancel {
    padding: 10px 15px;
    border-radius: 4px;
    background-color: white;
    color: #979598;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    border: 0;
  }
  .proceed-btn {
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #dec330;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border: 0;
  }
}
.notification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;

  .notification-title {
    font-size: 22px;
    font-family: 'FiraSans-Regular';
    color: #241a2e;
    line-height: 24px;
  }
  .success-image {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .create-new-record-text {
    margin: 10px 0;
    font-family: 'FiraSans-Medium';
    font-size: 12px;
    line-height: 17px;
    color: #777777;
    text-decoration: underline;
    cursor: pointer;
    text-transform: uppercase;
  }
  .success-text {
    justify-self: center;
    font-size: 22px;
    font-family: 'FiraSans-Regular';
    color: #241a2e;
    line-height: 24px;
  }
}
.loading-modal-body {
  display: flex;
  height: 20vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-body-loading-text {
  font-size: 22px;
  font-family: 'FiraSans-Regular';
  color: #241a2e;
  line-height: 24px;
}
.modal-body-loading-message {
  margin: 10px;
  font-family: 'FiraSans-Regular';
  font-size: 12px;
  line-height: 17px;
  color: #777777;
}
.csa {
  margin-bottom: 24px;
}
.trending-card {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .trending-btn-group {
    height: 38px;
    button {
      border-radius: 0;
      border: 1px solid #d1d1d1;
      background-color: white;
      color: #b1b4be;
      padding: 8px 8px;
      font-size: 12pt;
      font-family: 'FiraSans-Regular';
      cursor: pointer;
    }
    .btn-left {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right-width: 0;
    }
    .btn-right {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .active-btn {
      background-color: white;
      color: #241a2e;
    }
  }
  .MuiTabs-indicator {
    height: 5px !important;
  }

  .trending-card-title {
    font-size: 20px;
    font-family: 'FiraSans-Medium';
    line-height: 29px;
  }
}
.card {
  padding: 15px 15px 0 15px;
  display: flex;
  justify-content: space-between;
  line-height: 29px;

  .card-header {
    font-size: 20px;
    font-family: 'FiraSans-Medium';
  }
  .card-link {
    color: #0061ff;
    text-decoration: underline;
    font-family: 'FiraSans-Bold';
    font-size: 14px;
    cursor: pointer;
  }
}
.piechart-legend {
  .title {
    font-family: 'FiraSans-Medium';
    width: 140px;
    display: table-cell;
    font-size: 14px;
  }
  .value {
    font-family: 'FiraSans-Regular';
    font-size: 14px;
    width: 20px;
  }
}
.piechart-label {
  word-wrap: break-word;
}
.score-card {
  position: relative;
  font-family: 'FiraSans-Medium';
  height: 210px;
  margin-right: 20px;
  margin-bottom: 20px;
  > div:first-child {
    padding: 20px;
  }
  .score-card-label {
    color: #fff;
    font-size: 20px;
    line-height: 29px;
  }
  .score-card-value {
    color: #fff;
    font-size: 60px;
    line-height: 65px;
  }
  .inspection-value {
    color: #fff;
    font-size: 28px;
    line-height: 35px;
  }
  .inspection-label,
  .score-card-last-updated {
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    font-family: 'FiraSans-Light';
    font-style: italic;
    opacity: 0.8;
  }
  .last-date {
    padding-top: 12px;
  }
}
.border-danger {
  border-left: 6px solid #db2426;
}
.border-success {
  border-left: 6px solid #0da728;
}
.border-gray {
  border-left: 6px solid #beb9b9;
}
.inspection-card,
.violation-card,
.crashes-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'FiraSans-Medium';
  padding: 20px;
  height: 65px;
  background-color: #fff;
  cursor: pointer;
  .inspection-card-label,
  .violation-card-label,
  .crashes-card-label {
    font-size: 20px;
    line-height: 29px;
  }
  .inspection-card-value,
  .violation-card-value,
  .crashes-card-value {
    font-size: 30px;
    line-height: 45px;
    min-width: 45px;
    text-align: center;
  }

  .danger-bg {
    background-color: rgba(219, 36, 38, 0.15);
    color: #db2426;
  }
  .success-bg {
    background-color: rgba(13, 167, 40, 0.15);
    color: #0da728;
  }
}
.violation-card,
.crashes-card {
  margin-top: 5px;
}

.dashText {
  color: #b1b4be !important;
  background-color: '#fafa30';
  font-family: 'FiraSans-Medium';
  position: relative;
  top: 20px;
  right: 58px;
}

.info-icon {
  padding-right: 2px;
}

.disclaimer-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 20px;
}

.drivers-card {
  cursor: pointer;
  justify-content: flex-start;
  flex-grow: 1;
  .drivers-card-label {
    flex-grow: 1;
    margin: 0 10px;
  }
}

.fleet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'FiraSans-Medium';
  background-color: white;
  height: 100px;
  padding: 0 12px;
  cursor: pointer;

  .fleet-trucks {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 10px;
  }
  .fleet-trailers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 2px solid #c9c9c9;
    padding-left: 10px;
  }
  .fleet-icon {
    margin-right: 10px;
    img {
      vertical-align: middle;
    }
  }
  .wheel-icon {
    margin-right: 0px;
    img {
      vertical-align: middle;
    }
  }
  .fleet-label {
    font-size: 15px;
    line-height: 22px;
  }
  .fleet-value {
    font-size: 28px;
  }
}

.input-number {
  height: 43px;
  border-radius: 7px;
  background-color: #f8f8fa;
  font-size: 0.875rem;
  color: #241a2e;
  font-family: 'FiraSans-Medium';
  border: 0;
  padding: 0 10px;
}

.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root:disabled {
  opacity: 0.3 !important;
}
.basics-card .apexcharts-tooltip {
  z-index: 1000;
  // margin-left: 30px;
  // margin-right: 30px;
}
.tooltip {
  display: block;
  position: relative;
  z-index: 1000;
  width: 380px;
  padding: 10px;
  font-size: 12px;
  // height: 500px;
  .header {
    text-align: center;
  }

  p {
    word-wrap: break-word;
    white-space: normal;
  }
}
.basics-card {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.apexcharts-legend {
  width: 220px !important;
}
.apexcharts-toolbar::before {
  content: 'EXPORT';
}
.apexcharts-toolbar {
  color: #b1b4be;
  font-size: 13px;
  line-height: 1px;
}
.apexcharts-canvas ::-webkit-scrollbar-thumb {
  display: none;
}
// MUI Datepicker
.css-ctu4nj {
  display: none !important;
}
.css-1wvgxus-MuiCalendarPicker-viewTransitionContainer {
  overflow-y: hidden;
}
.css-dhopo2 {
  min-height: 230px !important;
}

.grid-header-stack .css-rotg1b-MuiGrid-root > .MuiGrid-item {
  padding-left: 0 !important;
  margin-left: 32px;
}

.MuiPaper-root {
  background-color: #fff;
}

.expiration-date {
  .MuiInputBase-input,
  .MuiButtonBase-root {
    color: #fff;
  }
}

.selected-search-type {
  background-color: #dec330;
}

#trend-details-search-types p {
  text-align: left;
}
